import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/C-MC7aG4mEQ">
    <SEO title="Every Precept Counts - The Puzzle" />
  </Layout>
)

export default SermonPost
